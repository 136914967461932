import { PrimaryButton } from '@orbica/component-sdk';
import { SecondaryButton } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGetPlanQuery } from '@Data/organizations/Api';
import { isTrialOrganizationState, orgPurchaseURLState, organizationsAuth0IdState, } from '@Data/organizations/Reducer';
import Styles from './UserMenu.scss';
const StyledCreditContainer = styled('div')(() => `
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 12px;
    background-color: #fff;
    gap: 4px;

    .title {
        font-weight: 700;
    }   
    .badgeContent {
        height: 4px;
        margin-left: 16px;
    }
`);
const StyledDetailsContainer = styled('div') `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-items: center;
    gap: 12px;

    .detail {
        font-weight: 700;
        justify-self: end;
    }

    .divider {
        grid-column: span 2;
        margin: 0;
    }

    .negative {
        color: ${ColorStyles.feedbackError400};
    }
`;
const OrgInfoBlock = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "title" }, props.title),
        React.createElement("div", { className: Styles.divider }),
        React.createElement(StyledDetailsContainer, null, props.children)));
};
export const OrganizationCreditInformation = (props) => {
    const auth0OrgId = useSelector(organizationsAuth0IdState);
    const isTrialOrg = useSelector(isTrialOrganizationState);
    const purchaseURL = useSelector(orgPurchaseURLState);
    const { data: planName } = useGetPlanQuery(Boolean(auth0OrgId) && !isTrialOrg ? auth0OrgId : skipToken);
    const remainingStu = !_isNil(props.org_available_stu) && !_isNil(props.org_required_stu)
        ? props.org_available_stu - props.org_required_stu
        : 0;
    return (!_isNil(props.org_available_stu) &&
        !_isNil(props.org_required_stu) && (React.createElement(StyledCreditContainer, null,
        React.createElement(OrgInfoBlock, { title: "Account Details" },
            React.createElement("span", null, "Plan"),
            React.createElement("span", { className: "detail" }, isTrialOrg ? 'Trial' : planName)),
        isTrialOrg && (React.createElement(SecondaryButton, { className: Styles.btn, size: "small", href: `https://www.orbica.com/pricing?${purchaseURL}&plan=${planName}`, target: "_blank" }, "Upgrade Plan")),
        React.createElement(OrgInfoBlock, { title: "Credit Balance" },
            React.createElement("span", null, "Current"),
            React.createElement("span", { className: "detail" }, props.org_available_stu.toLocaleString()),
            React.createElement("span", null, "Upcoming Runs*"),
            React.createElement("span", { className: "detail" }, props.org_required_stu.toLocaleString()),
            React.createElement("div", { className: cx(Styles.divider, 'divider') }),
            React.createElement("span", null, "Remaining"),
            React.createElement("span", { className: cx('detail', remainingStu < 0 ? 'negative' : '') }, remainingStu.toLocaleString())),
        React.createElement(PrimaryButton, { className: Styles.btn, href: `https://www.orbica.com/pricing?${purchaseURL}&plan=${planName}`, target: "_blank", size: "small" }, "Buy Credits"),
        React.createElement("div", { className: Styles.disclaimer },
            React.createElement("span", null,
                "* Credits are only spent on active jobs created by",
                ' ',
                isTrialOrg ? 'you' : 'your organisation')),
        React.createElement("div", { className: Styles.divider }))));
};
