import { Table } from '@orbica/component-sdk';
import { Tooltip } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AreaOfInterestOutline } from '@Components/AreaOfInterest/AreaOfInterestOutline';
import { AreaConversionTooltipTable } from '@Components/Tables';
import { useGetAoisQuery } from '@Data/aois/Api';
import { convertAreaFromSqm } from '@Services/helpers/Area';
import { getBasicOrderBy } from '@Services/helpers/GqlRest';
import { MapsContext } from '@Services/maps';
import { Navigation } from '@Services/navigation/Navigation';
import { IndicesResult } from './IndicesResult';
const AoiThumbnailContainer = styled('div') `
    width: 80px;
    height: 80px;
    cursor: pointer;
`;
export const AoisTable = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const mapsContext = useContext(MapsContext);
    const handleOpenAoi = (aoi) => {
        navigate(Navigation.schedule.aoiRuns.getUrl(jobId, aoi.id));
    };
    const handleOpenAoiMap = (event, feature, name) => {
        event.preventDefault();
        event.stopPropagation();
        mapsContext.onOpenAoiMap(feature, name);
    };
    const [sorting, setSorting] = useState([]);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState('');
    const handleSetFilter = (filter) => {
        setFilter(filter);
        setOffset(0);
    };
    const { data, isFetching } = useGetAoisQuery({
        jobId,
        limit: limit,
        offset: offset,
        order_by: getBasicOrderBy(sorting),
        filter: filter,
    });
    const { aois, count } = data !== null && data !== void 0 ? data : { aois: [], count: 0 };
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('aoi_geom', {
            header: 'Location',
            enableSorting: false,
            cell: (info) => {
                const feature = info.getValue();
                return (React.createElement(AoiThumbnailContainer, { onClick: (e) => handleOpenAoiMap(e, feature, info.row.original.name), title: "Expand map" },
                    React.createElement(AreaOfInterestOutline, { feature: feature, dimensions: {
                            height: 80,
                            width: 80,
                        }, color: ColorStyles.feedbackSuccess200 })));
            },
        }),
        columnHelper.accessor('name', {
            header: 'Name',
        }),
        columnHelper.accessor('area', {
            header: 'Area',
            cell: (info) => {
                const area = info.getValue();
                const tooltip = React.createElement(AreaConversionTooltipTable, { area: area });
                let output = React.createElement("span", null, convertAreaFromSqm(area));
                if (tooltip) {
                    output = React.createElement(Tooltip, { title: tooltip }, output);
                }
                return output;
            },
        }),
        columnHelper.accessor('aoi_run_results', {
            header: 'Latest Result',
            cell: (info) => {
                return (React.createElement(IndicesResult, { results: info.getValue(), aoiName: info.row.original.name }));
            },
            enableSorting: false,
            meta: {
                style: {
                    width: '1%',
                },
            },
        }),
    ];
    return (React.createElement(Table, { columns: columns, data: aois !== null && aois !== void 0 ? aois : [], onRowClick: handleOpenAoi, loading: isFetching, HeaderProps: {
            title: 'Schedule',
        }, SortingProps: {
            sorting: sorting,
            onSortingChange: (s) => setSorting(s),
        }, PaginationProps: {
            limit: limit,
            count: count,
            offset: offset,
            onSetLimit: (l) => setLimit(l),
            onSetOffset: (o) => setOffset(o),
        } }));
};
