var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TagType, baseApi, webApi, aoiReportfaasApi } from '@Services/redux';
import { transformDetailedAoi, transformGetJobAoisResponse } from './helpers';
export const aoisBaseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAois: build.query({
            query: (_a) => {
                var { jobId } = _a, params = __rest(_a, ["jobId"]);
                return {
                    url: `get_job_aois/${jobId}`,
                    method: 'POST',
                    data: params,
                };
            },
            transformResponse: (gqlResponse) => transformGetJobAoisResponse(gqlResponse),
            providesTags: (result, error, { jobId }) => [
                { type: TagType.AOI, jobId },
            ],
        }),
        getAoi: build.query({
            query: (aoiId) => ({ url: `get_aoi/${aoiId}`, method: 'GET' }),
            transformResponse: (gqlResponse) => transformDetailedAoi(gqlResponse.aoi[0]),
        }),
    }),
});
export const aoisWebApi = webApi.injectEndpoints({
    endpoints: (build) => ({
        getAoiBaselineCloudCover: build.query({
            query: (payload) => {
                return {
                    url: 'core/test-cloud-free',
                    data: payload,
                    method: 'POST',
                };
            },
        }),
    }),
});
export const aoisReportApi = aoiReportfaasApi.injectEndpoints({
    endpoints: (build) => ({
        getAoiReport: build.query({
            query: (payload) => ({
                url: 'reports',
                method: 'POST',
                data: payload,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
});
export const { useGetAoisQuery, useGetAoiQuery } = aoisBaseApi;
export const { useGetAoiBaselineCloudCoverQuery } = aoisWebApi;
export const { useGetAoiReportQuery } = aoisReportApi;
